/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        load_more(); // Load More Posts (Infinite Scroll)
        stick_navbars(); //Stick Navbars to top after scrolling
        // staff_directory_print();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  //Load TypeKit
  $(document).ready(function () {
    try{
      console.log("Loading Typekit");
      Typekit.load({ async: true });
    }catch(e){
      console.log(e);
    }
  });

})(jQuery); // Fully reference jQuery after this point.

function stick_navbars() {
  var top = jQuery("#mgh-top-menu").offset().top;
  var padding = jQuery("#mgh-top-menu").height();

  jQuery(window).bind('scroll', function () {
    if (jQuery(window).scrollTop() > top) {
      jQuery(document.body).css("padding-top", padding);
      jQuery("#mgh-top-menu").removeClass("navbar-static-top");
      jQuery("#mgh-top-menu").addClass("navbar-fixed-top");
    } else {
      jQuery("#mgh-top-menu").addClass("navbar-static-top");
      jQuery("#mgh-top-menu").removeClass("navbar-fixed-top");
      jQuery(document.body).css("padding-top",0);
    }
  });
}

function load_more() {
  var loading = false;
  jQuery('.post-nav-load-more').on('click', function () {
    var button =  jQuery(this);
    var div = button.closest("div");
    var query = {
      posts_per_page : posts_per_page,
      offset: offset,
      post_type: 'type_news'
    };


    if( ! loading ) {
      div.addClass("loading");
      setTimeout(function(){
        loading = true;
        jQuery.ajax({
          method: "GET",
          url: load_more_url,
          data: {
            action: 'ajax_load_more',
            query: query
          }
        })
            .done(function( res ) {
              console.log("Loaded!");
              div.before( res.data.content );
              if(res.data.remaining <= 0){
                button.hide();
              } else {
                offset = res.data.showing;
              }
              loading = false;
              div.removeClass("loading");
            })
            .fail(function(xhr, textStatus, e) {
              button.hide();
              console.log(xhr.responseText);
            });
      }, 500);
    }
    return false;
  });
}